<template>
  <video class="video" width="100%" controls :autoplay="autoplay">
    <source
      v-for="(source, i) in sources"
      :src="source.src"
      :type="source.type"
      :key="i"
    />
  </video>
</template>
<script>
export default {
  props: {
    sources: Array,
    autoplay: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style scoped>
.video {
  display: block;
  height: 80vh;
}
</style>
