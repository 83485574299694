<template>
  <v-card class="moholand-shadow mx-auto rounded-xl fill-height">
    <div class="video-parent grey lighten-3" :class="{ grey: !item.media }">
      <video width="100%" height="100%" v-if="item.media">
        <source :src="item.media.url + '#t=0.5'" type="video/mp4" />
      </video>
      <v-overlay color="black" absolute>
        <v-icon
          color="white"
          size="100"
          class="play-button"
          @click="item.media && $emit('onShowVideo', item.media)"
        >
          mdi-play-circle-outline
        </v-icon>
      </v-overlay>
    </div>

    <v-card-title class="one-line-text py-1">
      <small>{{ item.title }}</small>
    </v-card-title>
    <v-divider v-if="item.user" />
    <v-card-text class="py-1" v-if="item.user">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            class="rounded-lg"
            color="orange"
            dark
            text
            small
            v-bind="attrs"
            v-on="on"
            :disabled="disabledReference"
            @click="$router.push('/sellers/' + item.user.id)"
          >
            <v-avatar
              size="30"
              style="border-radius: 10px;border: 2px solid #f66200"
              class="justify-center"
            >
              <v-img
                :src="item.user.avatar_url || require('@/assets/img/man.jpg')"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      v-if="item.user.avatar_url"
                      indeterminate
                      size="small"
                      color="grey lighten-2"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-avatar>
          </v-btn>
        </template>
        <span>
          {{ item.user.first_name + " " + item.user.last_name }}
        </span>
      </v-tooltip>
    </v-card-text>

    <!-- <v-divider class="mx-2"/>

    <v-card-actions class="text-center">
      <v-rating
        v-if="product"
        :value="product.rate"
        color="amber"
        dense
        half-increments
        readonly
        size="18"
        class="mx-auto"
      />
    </v-card-actions> -->
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    disabledReference: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.video-parent {
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden !important;
}
.play-button {
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  position: absolute;
}
</style>
