<template>
  <div class="maindiv d-flex rounded-lg white moholand-shadow" style="flex: 1">
    <v-text-field
      v-model="search"
      flat
      dense
      solo
      label="جستجو..."
      hide-details
      class="rounded-r-lg my-auto"
    />
    <v-btn
      elevation="0"
      icon
      large
      class="rounded-l-lg rounded-0 orange darken-1"
      color="white"
      dark
      v-bind="$attrs"
      @click="onSubmit"
    >
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  methods: {
    onSubmit() {
      this.$emit("onSubmit", this.search);
    },
  },
};
</script>

<style scoped>
.maindiv {
  min-width: 300px;
}
</style>
