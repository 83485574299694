<template>
  <div class="main-div white rounded-lg text-center pa-4">
    <div
      class="absolute-fill orange darken-1 rounded-t-lg"
      :class="{ grey: !item.seller }"
    />
    <v-img
      :src="item.avatar_url || require('../../assets/img/man.jpg')"
      class="seller-img rounded-xl orange darken-1 mx-auto"
    />
    <!-- <v-rating color="yellow" dark :value="4" readonly class="ratingbar" /> -->
    <template v-if="item.seller">
      <div class="text-medium-bold seller-title">
        {{ item.seller.store_name }}
      </div>
    </template>
    <template v-else>
      <div class="text-medium-bold seller-title">
        {{ item.first_name || "-" }} {{ item.last_name || "" }}
      </div>
    </template>
    <v-btn
      elevation="0"
      dark
      color="orange darken-1"
      link
      :to="'/sellers/' + item.id"
      class="mt-4"
    >
      مشاهده پروفایل
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.main-div {
  position: relative;
  box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.1);
}
.absolute-fill {
  position: absolute;
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
}
.seller-img {
  border: 2px solid;
  width: 100px;
  height: 100px;
}
.ratingbar {
  transform: scale(-1, 1);
  max-width: 100%;
}
.seller-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
