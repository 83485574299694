<template>
  <div class="grey lighten-3">
    <simple-header
      hasLeftChild
      :routes="[{ title: 'خانه', url: '/' }, { title: 'انیمیشن ها' }]"
      title="انیمیشن ها"
    >
      <v-img
        :src="require('../../assets/icons/animation.png')"
        class="sp-img white-icon mr-md-auto ml-md-0 mx-auto"
      />
    </simple-header>
    <dialog-video v-model="videoDialog" :videoURLS="videoURL" />
    <v-container class="list-min-height">
      <v-row class="mt-4" align="center">
        <v-col cols="12">
          <search-bar @onSubmit="onSubmit" x-large />
        </v-col>
      </v-row>

      <v-row v-if="animations.length === 0 && !loading">
        <v-col>
          <h4 class="text-center grey--text my-5">هیچ انیمیشنی وجود ندارد!</h4>
        </v-col>
      </v-row>

      <v-row class="mt-4" v-if="loading">
        <v-col
          lg="3"
          md="4"
          sm="6"
          cols="12"
          v-for="(product, i) in 16"
          :key="'skeleton' + i"
        >
          <v-skeleton-loader
            type="image, card-heading"
            class="moholand-shadow"
          />
        </v-col>
      </v-row>

      <v-row v-else>
        <v-col
          sm="6"
          md="4"
          lg="3"
          cols="12"
          v-for="(item, i) in animations"
          :key="i + ' ' + playAnnouncerIndex == i"
        >
          <animation-item :item="item" @onShowVideo="onShowVideo" />
        </v-col>
      </v-row>
      <my-paginate
        v-model="current_page"
        :value="current_page"
        :length="pagesLength"
        @onChange="onPageChanged"
      />
    </v-container>
  </div>
</template>

<script>
import MyPaginate from "../../components/MyPaginate.vue";
import SellerItem from "../../components/Pages/SellerItem.vue";
import SearchBar from "../../components/SearchBar.vue";
import MyAxios from "../../constants/MyAxios";
import SimpleHeader from "../../components/SimpleHeader.vue";
import AnimationItem from "../../components/Pages/AnimationItem.vue";
import DialogVideo from "../../components/DialogVideo.vue";

export default {
  components: {
    SellerItem,
    MyPaginate,
    SearchBar,
    SimpleHeader,
    AnimationItem,
    DialogVideo,
  },
  data() {
    return {
      search: this.$route.query?.name || "",
      animations: [],
      loading: false,
      pagesLength: 1,
      path: null,
      total: null,
      playAnnouncerIndex: 0,
      videoDialog: false,
      videoURL: null,
      current_page: +this.$route.query?.page || 1,
    };
  },
  mounted() {
    this.GetData();
  },
  methods: {
    onShowVideo(item) {
      this.videoURL = [
        {
          src: item.url || "",
          type: "video/mp4",
        },
      ];
      this.videoDialog = true;
    },
    GetData: function() {
      window.scrollTo({ top: 0 });
      if (!this.loading) this.loading = true;
      MyAxios.get("/portfolios/index", {
        params: {
          with: ["user", "media"],
          search: this.search || undefined,
          page: this.$route.query?.page || 1,
          conditions: {
            status: 1,
          },
        },
      })
        .then((response) => {
          // this.animations = response.data.data.filter((val) => !!val.seller);
          this.animations = response.data.data;
          this.pagesLength = response.data.last_page;
          this.path = response.data.path;
          this.total = response.data.total;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    onSubmit(search) {
      this.search = search;
      this.$router.push({
        name: "Animations",
        query: {
          name: search || undefined,
        },
      });
      this.GetData();
    },
    onPageChanged(page) {
      this.$router.push({
        name: "Animations",
        query: {
          ...this.$route.query,
          page,
        },
      });
      this.GetData();
    },
  },
};
</script>

<style scoped>
.sp-img {
  max-width: 150px;
  max-height: 150px;
  object-fit: contain;
}
</style>
