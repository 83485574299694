<template>
  <v-dialog v-model="show" max-width="70%">
    <v-card>
      <v-card-title>
        <span>{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-0">
        <video-player :sources="videoURLS" :options="options" v-if="show"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VideoPlayer from "../components/VideoPlayer";
export default {
  components: {
    VideoPlayer,
  },
  data() {
    return {
      options: {
        autoplay: true,
      },
    };
  },
  props: {
    value: {
      required: true,
    },
    videoURLS: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: ""
    }
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
};
</script>

<style></style>
