<template>
  <v-pagination
    v-if="length > 1"
    v-model="page"
    color="orange darken-1"
    :length="length"
    total-visible="7"
    class="pagination moholand-shadow rounded-lg"
  />
</template>

<script>
export default {
  props: {
    value: {
      // v-model instance
      type: Number,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
  },
  computed: {
    page: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit("input", v);
        this.$emit("onChange", v)
      },
    },
  },
};
</script>

<style>
.pagination {
  margin: 10px auto 0 auto;
  background: white;
  max-width: 400px;
}
.v-pagination > li button {
  box-shadow: none !important;
  border: 3px solid #fb8c00;
  font-weight: bold;
}
.theme--light.v-pagination .v-pagination__item {
  color: #fb8c00 !important;
}

.theme--light.v-pagination .v-pagination__item--active {
  color: #fff !important;
}
.v-pagination__navigation .v-icon,
.v-pagination__more {
  color: #fb8c00 !important;
}
</style>
