<template>
  <div class="d-flex flex-wrap">
    <div class="d-flex my-auto" v-for="(route, i) in routes" :key="i">
      <v-btn :color="dark ? 'grey darken-2' : 'white'" text :to="route.url">
        {{ route.title }}
      </v-btn>
      <div
        class="my-auto"
        :class="dark ? 'grey--text darken-2' : 'white--text'"
        v-if="i < routes.length - 1"
      >
        {{ " > " }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // routes should have object in each indices and each object should have "title" and "url"
    routes: {
      type: Array,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>
