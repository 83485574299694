<template>
  <div class="simple-header pa-4">
    <v-container>
      <v-row class="text-center text-md-right">
        <v-col cols="12" :md="hasLeftChild ? 6 : 12" class="my-auto">
          <h1 class="white--text">{{ title }}</h1>
          <my-routes-list :routes="routes" />
        </v-col>
        <v-col v-if="hasLeftChild" cols="12" md="6" class="text-center">
          <slot />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MyRoutesList from "./MyRoutesList.vue";
export default {
  components: { MyRoutesList },
  props: {
    hasLeftChild: {
      type: Boolean,
      default: false,
    },
    routes: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.simple-header {
  width: 100%;
  background: url("../assets/header.jpg");
}
</style>
